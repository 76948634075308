import { calculateGain } from './performance';

export const transformMdbData = (data, dateObj) => {
    const {
        fiveYearDate, halfYearDate, maxDate, maxPreviousYearDate, previousDate, 
        qtrDate, threeYearDate, weeklyDate, yearDate
    } = dateObj;

    const result = [];
    let prevSymbol = '';
    let currentPrice = '';
    let valueDate = '';
    let closeValue = '';

    const historicalPrices = {
        previousYearEndPrice: 0,
        threeYearEndPrice: 0,
        fiveYearEndPrice: 0,
        sevenYearEndPrice: 0,
        lastQtrEndPrice: 0,
        lastHalfYrEndPrice: 0,
        yearEndPrice: 0,
        previousDayPrice: 0,
        lastweekEndPrice: 0,
    };

    data.forEach(key => {
        const { SYMBOL: currSymbol, VALUE_DATE, CLOSE_VALUE } = key;

        if (currSymbol !== prevSymbol && prevSymbol !== '') {
            const {
                previousYearEndPrice, threeYearEndPrice, fiveYearEndPrice, sevenYearEndPrice,
                lastQtrEndPrice, lastHalfYrEndPrice, yearEndPrice, previousDayPrice, lastweekEndPrice
            } = historicalPrices;

            const performanceMetrics = {
                ytdPerformance: calculateGain(currentPrice, yearEndPrice),
                qtrPerformance: calculateGain(currentPrice, lastQtrEndPrice),
                biannualPerformance: calculateGain(currentPrice, lastHalfYrEndPrice),
                annualPerformance: calculateGain(currentPrice, previousYearEndPrice),
                threeYearPerformance: calculateGain(currentPrice, threeYearEndPrice),
                fiveYearPerformance: calculateGain(currentPrice, fiveYearEndPrice),
                sevenYearPerformance: calculateGain(currentPrice, sevenYearEndPrice),
                weeklyPerformance: calculateGain(currentPrice, lastweekEndPrice),
                oneDayPerformance: calculateGain(currentPrice, previousDayPrice),
            };

            result.push({
                [prevSymbol]: {
                    valueDate,
                    closeValue,
                    currentPrice,
                    ...historicalPrices,
                    ...performanceMetrics,
                }
            });

            // Reset historical prices for the new symbol
            Object.keys(historicalPrices).forEach(key => historicalPrices[key] = 0);
        }

        valueDate = VALUE_DATE;
        closeValue = CLOSE_VALUE;

        if (valueDate === maxDate) {
            currentPrice = closeValue;
        } else if (valueDate === maxPreviousYearDate) {
            historicalPrices.previousYearEndPrice = closeValue;
        } else if (valueDate === threeYearDate) {
            historicalPrices.threeYearEndPrice = closeValue;
        } else if (valueDate === fiveYearDate) {
            historicalPrices.fiveYearEndPrice = closeValue;
        } else if (valueDate === qtrDate) {
            historicalPrices.lastQtrEndPrice = closeValue;
        } else if (valueDate === halfYearDate) {
            historicalPrices.lastHalfYrEndPrice = closeValue;
        } else if (valueDate === yearDate) {
            historicalPrices.yearEndPrice = closeValue;
        } 
        
        // Corrected conditions to avoid overlap
        if (valueDate === weeklyDate) {
            historicalPrices.lastweekEndPrice = closeValue;
        }

        if (valueDate === previousDate) {
            historicalPrices.previousDayPrice = closeValue;
        }

        prevSymbol = currSymbol;
    });

    return result;
};

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CircularProgress from '@mui/joy/CircularProgress';
import { useSearchParams } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import OtpVerify from './OtpVerify';
import { BASE_URL } from '../../../constant/index'

const RightSection = () => {
    const [ isLoading, setLoading ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');
    const [ successMsg, setSuccessMsg ] = useState('');
    const [ open, setOpen ] = React.useState(false);
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const isDematButton = searchParams.get("isDemat");
    const [input, setInput] = useState({
        userName: "",
        userEmail: "",
        password: "",
        primaryPhone: "",
        isDemat: isDematButton? isDematButton: false,
    });

    const handleClose = () => {
        setOpen(false);
    };

    const onUserNameChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            userName: event.target.value,
        }));
    }

    const onUserEmailChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            userEmail: event.target.value,
        }));
    }

    const onPhoneChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            primaryPhone: event.target.value,
        }));
    }

    const onPasswordChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            password: event.target.value,
        }));
    }

    const onDematChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            isDemat: event.target.checked,
        }));
    }

    const registerUser = async (otpFlag) => {
        const { userName, userEmail, password, primaryPhone, isDemat } = input;
        try {
            const { data } = await axios.post(BASE_URL+'/registerUser', { userName, userEmail, password, primaryPhone, isDemat, isOTPVerified: otpFlag });
            if(data?.auth) {
                const { auth, msg } = data;
                navigate('/sign-in', {
                    state: {
                        auth, msg
                    }
                });
            }
        } catch(err) {
            setErrorMsg("We encountered an issue, please try after some time or reach out to help@capitaltredns.in");
        }
    }

    const signUpAction = async (e) => {
        e.preventDefault();
        setErrorMsg('');
        setSuccessMsg('');
        const { userName, password, userEmail, primaryPhone } = input;
        if (userName && password && userEmail && primaryPhone) {
            await registerUser(false);
            // try {
            //     const { data } = await axios.post(BASE_URL+'/isUserExist', { userEmail, primaryPhone });
            //     if(data?.isExist) {
            //         setErrorMsg(data.msg);
            //         return false;
            //     } else {
            //         // await axios.post(BASE_URL+'/sendOtp', { primaryPhone: '+91 '+primaryPhone });
            //         setOpen(true);
            //     }
            // } catch(err) {
            //     await registerUser(false);
			//     setLoading(false);
            // }        
        }
    }
    
    return (
        <>
            <form onSubmit={signUpAction}>
                {
                    errorMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px' }} spacing={2}>
                            <Alert severity="error">{errorMsg}</Alert>
                        </Stack>
                    )
                }

                {
                    successMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px' }} spacing={2}>
                            <Alert severity="success">{successMsg}</Alert>
                        </Stack>
                    )
                }

                {
                    isLoading && (
                        <div className="loader-container">
                            <CircularProgress size="lg" />
                        </div>
                    )
                }

                <div className="mb-3 mt-5 input-group-lg">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input name="userName" type="text" className="form-control" id="reqdName" placeholder="Enter your name" onChange={onUserNameChange} required />
                </div> 

                <div className="mb-3 input-group-lg">
                    <label htmlFor="mobile-no" className="form-label">Mobile</label>
                    <input name="primaryPhone" type="number" className="form-control" id="reqdMobile" placeholder="Enter your mobile number" onChange={onPhoneChange} required />
                </div> 

                <div className="mb-3 input-group-lg">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input id="reqdEmail" name="email" type="email" className="form-control" placeholder="Enter your email" onChange={onUserEmailChange} required />
                </div>            

                <div className="mb-3 input-group-lg">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" id="reqdPassword" name="password" className="form-control" placeholder="Create a password" onChange={onPasswordChange} required />
                    <div className="form-text" id="basic-addon4">Must be at least 8 characters.</div>
                </div>

                <div className="mb-3 input-group-lg">
                    <input type="checkbox" htmlFor="demat" className="form-label m-1" onChange={onDematChange} checked={input.isDemat}/>
                    <label htmlFor="demat" className="form-text">Need to open a Demat Account?</label><br></br>
                </div>

                <div className="d-grid gap-3 mt-4">
                    <button type="submit" className="btn btn-primary button-cus btn-lg" disabled={isLoading}>Get started</button>
                </div>
            </form>

            <OtpVerify 
                openModal={open} 
                handleClose={handleClose} 
                userInput={input} 
                registerUser={registerUser} 
            />
        </>
    )
}

export default RightSection;